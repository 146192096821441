import { Route, Redirect } from 'react-router-dom';
import { login } from '../constants/Url';
import * as LS from '../_helpers/LS'
import { USER_LOCALSTOREGE_LOGIN } from '../constants/UserConst';

export function PrivateRoute({ component: Component, ...rest }) {
    //const auth = useRecoilValue(authAtom);
    return (
        <Route {...rest} render={props => {
            if (!LS.getLS(USER_LOCALSTOREGE_LOGIN)) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: login, state: { from: props.location } }} />
            }
            // authorized so return component
            return <Component {...props} />
        }} />
    );
}