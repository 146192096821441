export function getLS (key) {
    return JSON.parse(localStorage.getItem(key))
}

export function setLS(key, data) {
    localStorage.setItem(key, JSON.stringify(data))
}

export function deleteLS(key) {
    localStorage.removeItem(key)
}

export function clearLS() {
    localStorage.clear();
}