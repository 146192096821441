//import React from 'react'
import React, { Suspense } from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress';

import * as Url from '../constants/Url'
import { PrivateRoute } from './PrivateRoute';
//import { PrivateRouteCheckLogin } from './PrivateRouteCheckLogin';

const LoginPage = React.lazy(() => import('../pages/users/Login'));
const NotFoundPage = React.lazy(() => import('../pages/users/NotFoundPage'));
const ChangePassPage = React.lazy(() => import('../pages/users/ChangePass'));
//const MotDePassPage = React.lazy(() => import('../pages/users/MotDePass'));


const LogoutPage = React.lazy(() => import('../pages/users/Logout'));
//const HomePage = React.lazy(() => import('../pages/home'));

//const TestPage = React.lazy(() => import('../views/contact-view'));


const RegisterPage = React.lazy(() => import('../pages/users/Register'));

const GuideStylePage = React.lazy(() => import('../components/gui-master/GuideStyle'));
const ElementPage = React.lazy(() => import('../components/gui-master/Element'));
const ComponentPage = React.lazy(() => import('../components/gui-master/Components'));
const ProjectPage = React.lazy(() => import('../components/gui-master/Project'));
const ProjectMenuInputPage = React.lazy(() => import('../components/gui-master/ProjectMenuInput'));

const Profile = React.lazy(() => import('../pages/users/profile/UserProfileRoot'));
const Dashboard = React.lazy(() => import('../pages/dashboard/DashboardProject'));

const Projects = React.lazy(() => import('../pages/project/RootProject'));
const WorkspaceGestionMembres = React.lazy(() => import('../pages/workspace/WorkspaceGestionMembres'));
const MarketPlace = React.lazy(() => import('../pages/marketplace/MarketPlace'));
const MarketPlaceDescription = React.lazy(() => import('../pages/marketplace/MarketPlaceDescription'));

const LoginError = React.lazy(() => import('pages/users/LoginError'));

//const MainPage = React.lazy(() => import('../components/common/new-project/Main'));


// const LoginSocialPage = React.lazy(() => import('../users/components/LoginSocial'));
// const ForgotPassPage = React.lazy(() => import('../users/components/ForgotPass'));
// const ResetPassPage = React.lazy(() => import('../users/components/ResetPass'));
// const ResetPassAppPage = React.lazy(() => import('../users/components/ResetPassApp'));
// const RegisterProPage = React.lazy(() => import('../users/components/RegisterPro'));
// const ProfilePage = React.lazy(() => import('../users/components/Profile'));
// const ReferencePage = React.lazy(() => import('../users/components/Reference'));
// const ModifyPassPage = React.lazy(() => import('../users/components/ModifyPass'));
const VerifyEmail = React.lazy(() => import('../pages/users/VerifyEmail'));
const AccountVerificationEmail = React.lazy(() => import('../pages/users/AccountVerificationEmail'));
const ChangePassWord = React.lazy(() => import('../pages/users/ChangePassWord'));
const AcceptInvite = React.lazy(() => import('../pages/users/AcceptInvite'));


const appRouter = () => (
    <Suspense fallback={<LinearProgress />}>
        <BrowserRouter>
            <Switch>
                <Route path={ Url.login } component={ LoginPage } />
                <Route path={ Url.accountVerificationEmail } component={ AccountVerificationEmail } />
                <Route path={ Url.forgotPassWord } component={ ChangePassWord } />
                {/* <PrivateRouteCheckLogin path={ Url.emailMPD } component={ MotDePassPage } /> */}
                <Route path={ Url.verifyMail } component={ VerifyEmail } />
                <Route path={ Url.register } component={ RegisterPage } />
                <Route path={ Url.logout } render={()=> <LogoutPage />} />

                <Route path={ Url.LoginError } render={()=> <LoginError />} />

                <PrivateRoute path={ Url.homeUrl + Url.profile } component={ Profile } />
                <PrivateRoute exact path={ Url.home } component={ Dashboard } />
                <PrivateRoute exact path={ Url.homeUrl } component={ Dashboard } />
                <PrivateRoute exact path={ Url.dashboard } component={ Dashboard } />

                <PrivateRoute path={ Url.guidstyle } component={ GuideStylePage } />

                <PrivateRoute path={ Url.projectView } component={ Projects } />

                <PrivateRoute exact path={ Url.element } component={ ElementPage } />
                <PrivateRoute exact path={ Url.component } component={ ComponentPage } />
                <PrivateRoute exact path={ Url.project } component={ ProjectPage } />
                <PrivateRoute exact path={ Url.projectmenuinput } component={ ProjectMenuInputPage } />

                <PrivateRoute exact path={ Url.workspaceaddmember } component={ WorkspaceGestionMembres } />

                <PrivateRoute exact path={ Url.homeUrl + Url.marketplace } component={ MarketPlace } />
                <PrivateRoute exact path={ Url.marketplaceDescription } component={ MarketPlaceDescription } />

                {/* <PrivateRoute exact path={ Url.acceptInviteEmailMember } component={ AcceptInvite } /> */}
                <Route path={ Url.acceptInviteEmailMember } render={()=> <AcceptInvite />} />
                {/*<RouteCheckLogin path={Url.loginsocial} component={LoginSocialPage} />

                <RouteCheckLogin path={Url.forgotPass} component={ForgotPassPage} />
                <RouteCheckLogin path={Url.resetPass + '/:code'} component={ResetPassPage} />
                <RouteCheckLogin path={Url.resetPassApp + '/:code'} component={ResetPassAppPage} />
                <RouteCheckLogin path={Url.register} component={RegisterPage} />
                <PrivateRoutePro path={Url.registerpro} component={RegisterProPage} />

                <PrivateRoute path={Url.modifypass} component={ModifyPassPage} />
                <PrivateRoute path={Url.user} component={ProfilePage} /> */}
                
                <Route render={()=> <NotFoundPage />} />

            </Switch>
        </BrowserRouter>
    </Suspense>
);

export default appRouter;