const config = {
    template : "snes",
    title: "La Scene Independante",
    favicon: "https://appli.lasceneindependante.org/wp-content/themes/gridster-lite/img/snes_flavicon.ico",
    layout: "components/Layout.js",
    logo_page_login: "https://appli.lasceneindependante.org/wp-content/uploads/logo_lasceneindependante_quadri.svg",
    domain : "appli.lasceneindependante.alpaginodirect.com",
    id_ws: "63f4fe2481a9d",
}

export default config