let configuration = null;

export function set(key, value) {
    if (configuration == null) {
        configuration = {};
    }
    configuration[key] = value
}

export function get(key = null, vdefault = null) {
    if (configuration == null) {
        configuration = {};
    }
    if(key === null) {
        return configuration
    }
	return configuration?.[key] ?? vdefault;
}

