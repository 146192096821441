import './App.scss';
import React from 'react';
//import { MuiThemeProvider,  StylesProvider } from '@material-ui/core/styles'
import AppRouter from './routers/AppRouter'
//import spacing from '@material-ui/core/styles/spacing'
import 'font-awesome/css/font-awesome.min.css';
import { RecoilRoot } from 'recoil';

const App = () => (
    <RecoilRoot>
        <AppRouter />
    </RecoilRoot>
);

export default App;
