import React from 'react'
import { get, set } from '_helpers/theme/configuration';

import snes  from 'pages/project/snes/config.js'

let configDefault = {
    template : "global",
    title : "Alpagino",
    favicon: "/al.png",
    layout: null, //get default 
    logo_page_login: null,
    domain: null,
    id_ws: null,
}


export function  init() {
    let domain = window.location.href
    // domain = "appli.lasceneindependante.alpaginodirect.com"

    let config = configDefault

    if(domain.includes("appli.lasceneindependante.alpaginodirect.com") || domain.includes("appli.lasceneindependante.alpagino.com")) {
        //overrid config
        config = {...config,...snes}
    }

    Object.keys(config).forEach(function(key, index) {
        set(key, config[key])
    });
}

