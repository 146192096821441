import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
//import StoreProvider from "./utilities/store";
import i18next from "i18next";
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

import config from "./_helpers/theme"

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'fr',                              // language to use
  resources: {
	  en: {
		  common: common_en               // 'common' is our custom namespace
	  },
	  fr: {
		  common: common_fr
	  },
  },
});
config.init();

document.title = config.get("title");
document.querySelector("link[rel~='icon']").href = config.get("favicon");

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
	  {/* <StoreProvider> */}
		<App />
	  {/* </StoreProvider> */}
	</I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
